import { ahoy } from "~/src/ahoy"

declare global {
  interface Window {
    ahoy: typeof ahoy
  }
}

// @ts-expect-error compiler errors even though declared types are functioning as expected
window.ahoy = ahoy
